import Grid from "@mui/material/Grid";
import { Control } from "react-hook-form";
import ControlledSelect from "../FormElements/ControlledSelect";
import ControlledTextField from "../FormElements/ControlledTextField";
import { Collapse } from "@mui/material";

type Props = {
  control: Control<any>;
  options: {value: string; label: string}[];
  watch?: (
    names?: string | string[] | ((data: any, options: any) => void)
  ) => unknown;
};

export default function FormPartServiceDescription(props: Props) {
  const watchServiceDescription = props.watch?.("serviceDescription");

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3 className="h3">
            Leistungs&shy;beschreibung
          </h3>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledSelect
            name="serviceDescription"
            label="Leistung auswählen"
            control={props.control}
            options={[...props.options, { value: "other", label: "Andere" }]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Collapse in={watchServiceDescription === "other"}>
            <ControlledTextField
              name="serviceDescriptionOther"
              label="Andere Leistung beschreiben"
              rules={{ required: watchServiceDescription === "other" ? "Bitte Leistung beschreiben" : false }}
              control={props.control}
            />
          </Collapse>
        </Grid>
      </Grid>
    </>
  );
}
