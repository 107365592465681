import Grid from "@mui/material/Grid";
import { Control } from "react-hook-form";
import ControlledTextField from "../FormElements/ControlledTextField";
import ControlledDatePicker from "../FormElements/ControlledDatePicker";
import ControlledNumberInput from "../FormElements/ControlledNumberInput";

type Props = {
  control: Control<any>;
  disabled?: boolean;
};

export default function FormPartFamilyMember(props: Props) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3 className="h3">Angehörige:r</h3>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name="lastName_fm"
            label="Familienname/Nachname"
            disabled={props.disabled}
            rules={{ required: !props.disabled ? "Bitte Nachname angeben" : false }}
            control={props.control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name="firstName_fm"
            label="Vorname/n"
            disabled={props.disabled}
            rules={{ required: !props.disabled ? "Bitte Vorname angeben" : false }}
            control={props.control}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ControlledTextField
            name="address_fm"
            label="Straße, Nr."
            disabled={props.disabled}
            rules={{ required: !props.disabled ? "Bitte Adresse angeben" : false }}
            control={props.control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledNumberInput
            name="zip_fm"
            label="PLZ"
            disabled={props.disabled}
            rules={{ required: !props.disabled ? "Bitte Postleitzahl angeben" : false }}
            control={props.control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name="city_fm"
            label="Ort"
            disabled={props.disabled}
            rules={{ required: !props.disabled ? "Bitte Postleitzahl angeben" : false }}
            control={props.control}
          />
        </Grid>

        <Grid item xs={12}>
          <h3 className="h3">Versicherungsnummer Angehörige:r</h3>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledNumberInput
            name="socialSecurityNumber_fm"
            label="Sozialversicherungsnummer (10 Ziffern)"
            rules={{
              required: !props.disabled ? "Bitte Sozialversicherungsnummer angeben" : false,
              minLength: {
                value: 10,
                message: "Bitte 10 Ziffern angeben",
              },
              maxLength: {
                value: 10,
                message: "Bitte 10 Ziffern angeben",
              },
            }}
            disabled={props.disabled}
            control={props.control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledDatePicker
            name="birthday_fm"
            label="Geburtsdatum"
            disabled={props.disabled}
            rules={{ required: !props.disabled ? "Bitte Geburtsdatum angeben" : false }}
            control={props.control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name="email_fm"
            label="E-Mail Adresse"
            disabled={props.disabled}
            control={props.control}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name="phone_fm"
            label="Telefonnummer"
            disabled={props.disabled}
            control={props.control}
          />
        </Grid>
      </Grid>
    </>
  );
}
