import { Box, Button } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Grid from "@mui/material/Grid";
import { SubmitHandler, set, useForm } from "react-hook-form";
import ControlledTextField from "../components/FormElements/ControlledTextField";
import { useNavigate } from "react-router-dom";
import Captcha from "../components/FormElements/Captcha";
import useTokenService, { TokenType } from "../services/TokenService";
import { useState } from "react";

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
};

export default function Registration() {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<TokenType>({
    defaultValues,
  });
  const [captchaResponse, setCaptchaResponse] = useState<string>("");
  const [isCaptchaValid, setIsCaptchaValid] = useState<boolean | undefined>(
    undefined
  );

  const sendToken = useTokenService((state) => state.sendToken);

  const onSubmitHandler: SubmitHandler<TokenType> = (data) => {
    if (captchaResponse === "") {
      setIsCaptchaValid(false);
      return;
    } else {
      setIsCaptchaValid(true);
    }

    sendToken({ ...data, captchaResponse: captchaResponse })
      .then((resp) => {
        navigate(`/login?firstName=${data.firstName}&lastName=${data.lastName}&email=${data.email}`, { state: { ...data } });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onCaptchaCallbackHandler = (token: string, ekey: string) => {
    setCaptchaResponse(token);
  };

  return (
    <>
      <Box component="div" className="section__main">
        <form autoComplete="off" onSubmit={handleSubmit(onSubmitHandler)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3 className="h3">Zugriffscode per Email anfordern</h3>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ControlledTextField
                name="lastName"
                label="Familienname/Nachname"
                rules={{ required: "Bitte Nachname angeben" }}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ControlledTextField
                name="firstName"
                label="Vorname/n"
                rules={{ required: "Bitte Vorname angeben" }}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ControlledTextField
                name="email"
                label="E-Mail Adresse"
                rules={{ required: "Bitte E-Mail Adresse angeben" }}
                control={control}
                type="email"
              />
            </Grid>
            <Grid item xs={12}>
              <Captcha onCaptchaCallback={onCaptchaCallbackHandler} />
              {isCaptchaValid == false && (
                <div className="validationMsg">Bitte Captcha überprüfen.</div>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                startIcon={<ArrowForwardIcon />}
                type="submit"
              >
                Absenden
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box component="aside" className="section__aside">
        <div className="notice notice--important">
          <h3 className="h3">
            <InfoIcon /> Ihr Zugriffscode
          </h3>
          <p className="copytext">
            Sie bekommen nach dem Absenden Ihrer Daten einen Zugriffscode
            zugesendet. Nach erfolgreicher Authentifizierung erhalten Sie
            Zugriff auf das Antragsformular.
          </p>
        </div>
        <Button
          variant="contained"
          startIcon={<ArrowForwardIcon />}
          onClick={() => navigate("/login")}
        >
          Mit bestehendem Code einloggen
        </Button>
      </Box>
    </>
  );
}
