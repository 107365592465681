import { Control, Controller } from "react-hook-form";
import EuroIcon from "@mui/icons-material/Euro";
import { TextField } from "@mui/material";
import { formElementSize } from "../../utils/constants";

type Props = {
  name: string;
  label: string;
  rules?: any;
  inputProps?: any;
  disabled?: boolean;
  control: Control<any>;
  type?: "number" | "money";
};

export default function ControlledNumberInput(props: Props) {
  let adornment = <></>;

  switch (props.type) {
    case "money":
      adornment = <EuroIcon />;
      break;
    default:
      break;
  }

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        pattern: {
          value: /^([0-9])*[,.]?([0-9])*$/,
          message: "Bitte eine Zahl eingeben",
        },
        ...props.rules,
      }}
      render={({
        field: { onChange, value, disabled },
        fieldState: { error },
      }) => (
        <TextField
          helperText={error ? error.message : null}
          size={formElementSize}
          error={!!error}
          required={!!props.rules?.required}
          disabled={props.disabled ?? disabled}
          onChange={onChange}
          value={value}
          fullWidth
          label={props.label}
          variant="outlined"
          InputProps={{ startAdornment: adornment, ...props.inputProps }}
          // type number is not properly supported by mui textfield
          type="text"
        />
      )}
    />
  );
}
