import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { Fragment, useState } from "react";

import "./ControlledFileUpload.scss";

type Props = {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  control: Control<any>;
  onFileUpload: (files: File[]) => void;
};

export default function ControlledFileUpload(props: Props) {
  const [fileList, setFileList] = useState<File[]>([]);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
    value: "",
  });

  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];
    setFileList((prev) => [...prev, file]);

    props.onFileUpload(Array.from(event.target.files));
  };

  const onDeleteDocumentHandler = (index: number) => () => {
    const newFileList = fileList.filter((file, i) => i !== index);
    setFileList(newFileList);
  };

  return (
    <>
      {fileList.length > 0 && (
        <List className="fileUpload__list" dense={true} style={{ marginBlockEnd: "10px" }}>
          {fileList.map((file, index) => {
            return (
              <Fragment key={index}>
                {index > 0 && <Divider />}
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={onDeleteDocumentHandler(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemIcon>
                    <InsertDriveFileOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={file.name} />
                </ListItem>
              </Fragment>
            );
          })}
        </List>
      )}
      <Button
        component="label"
        variant="outlined"
        startIcon={<UploadFileIcon />}
        disabled={fileList.length >= 5}
      >
        <Controller
          name={props.name}
          control={props.control}
          rules={{ required: props.required }}
          render={({
            //field: { onChange, value, disabled },
            fieldState: { error },
          }) => (
            <>
              <VisuallyHiddenInput
                type="file"
                accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                onChange={handleFileUpload}
              />
              {!!error && <div>Bitte mindestens ein Dokument auswählen</div>}
            </>
          )}
        />
        {props.label}
      </Button>
    </>
  );
}
