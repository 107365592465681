import Grid from "@mui/material/Grid";
import { Control, useForm } from "react-hook-form";
import IBAN from "iban";
import ControlledTextField from "../FormElements/ControlledTextField";
import { useEffect } from "react";

type Props = {
  control: Control<any>;
};

export default function FormPartBankData(props: Props) {

  function validateIban(iban: string) {
    return IBAN.isValid(iban) ? true : "Ungültige IBAN";
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3 className="h3">Bankdaten</h3>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name="iban"
            label="IBAN"
            rules={{ required: true, validate: validateIban }}
            control={{
              ...props.control,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField name="bic" label="BIC" control={props.control} />
        </Grid>
      </Grid>
    </>
  );
}
