import { CssBaseline } from "@mui/material";
import styleVariables from "./styles/_variables.module.scss";
import "./styles/_mui-overrides.scss";
import "./App.scss";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";

const theme = createTheme({
  palette: {
    primary: {
      main: styleVariables.colorBlue,
      light: styleVariables.colorLightblue,
      dark: styleVariables.colorDarkblue,
      contrastText: styleVariables.colorWhite,
    },
  },
  typography: {
   "fontFamily": `voestalpine, "Neue Helvetica W02", "Helvetica Neue", "Segoe UI",
   arial, helvetica, sans-serif`,
   "fontSize": 14,
   "fontWeightLight": 200,
   "fontWeightRegular": 300,
   "fontWeightMedium": 400
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box component="div" className="app">
        <Header />
        <Main />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;
