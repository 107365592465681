import Grid from "@mui/material/Grid";
import { Control } from "react-hook-form";
import ControlledTextField from "../FormElements/ControlledTextField";
import ControlledDatePicker from "../FormElements/ControlledDatePicker";
import ControlledNumberInput from "../FormElements/ControlledNumberInput";

type Props = {
  control: Control<any>;
};

export default function FormPartApplicant(props: Props) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3 className="h3">
            Antragsteller:in (=begünstigte:r Hauptversicherte:r)
          </h3>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name="lastName"
            label="Familienname/Nachname"
            //disabled={true}
            control={props.control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name="firstName"
            label="Vorname/n"
            //disabled={true}
            control={props.control}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ControlledTextField
            name="address"
            label="Straße, Nr."
            rules={{ required: "Bitte Adresse angeben" }}
            control={props.control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledNumberInput
            name="zip"
            label="PLZ"
            rules={{ required: "Bitte Postleitzahl angeben" }}
            control={props.control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name="city"
            label="Ort"
            rules={{ required: "Bitte Postleitzahl angeben" }}
            control={props.control}
          />
        </Grid>

        <Grid item xs={12}>
          <h3 className="h3">Versicherungsnummer Antragsteller:in</h3>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledNumberInput
            name="socialSecurityNumber"
            label="Sozialversicherungsnummer (10 Ziffern)"
            rules={{
              required: "Bitte gültige Sozialversicherungsnummer angeben",
              minLength: {
                value: 10,
                message: "Bitte 10 Ziffern angeben",
              },
              maxLength: {
                value: 10,
                message: "Bitte 10 Ziffern angeben",
              },
            }}
            control={props.control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledDatePicker
            name="birthday"
            label="Geburtsdatum"
            rules={{ required: "Bitte Postleitzahl angeben" }}
            control={props.control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name="email"
            label="E-Mail Adresse"
            //disabled={true}
            control={props.control}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name="phone"
            label="Telefonnummer"
            control={props.control}
          />
        </Grid>
      </Grid>
    </>
  );
}
