import Grid from "@mui/material/Grid";
import { Control } from "react-hook-form";
import { Box } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import ControlledFileUpload from "../FormElements/ControlledFileUpload";

type Props = {
  control: Control<any>;
  onFileUpload: (files: File[]) => void;
};

export default function FormPartDocuments(props: Props) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3 className="h3">Unterlagen</h3>
        </Grid>
        <Grid item xs={12}>
          <Box component="div" className="notice">
            <h3 className="h3">
              <InfoIcon /> Erforderliche Unterlagen
            </h3>
            <div className="copytext">
              <ul className="simple">
                <li>
                  die auf den Namen des/der Leistungsbeziehers:in lautende
                  Rechnung sowie das vollständig ausgefüllte, unterzeichnete
                  Antragsformular
                </li>
                <li>
                  eine Zahlungsbestätigung, aus der hervorgeht, dass die
                  Rechnung von Ihnen beglichen wurde wie z.B.:
                  <ul>
                    <li>
                      „bar bezahlt“ Vermerk inkl.Stempel vom/von der
                      Leistungserbringer:in auf der Rechnung
                    </li>
                    <li>
                      einen Nachweis auf dem der Zahlungsausgang ersichtlich ist
                    </li>
                  </ul>
                </li>
                <li>
                  Leistungsbestätigung oder Ablehnung der ÖGK bzw.
                  Leistungsbestätigung der privaten Versicherung
                </li>
                <li>
                  sonstige zum Nachweis des Anspruchs erforderliche Unterlagen
                  (z.B. Nachweis des Bezuges der Familienbeihilfe ab
                </li>
                <li>
                  dem vollendeten 15. Lebensjahr des Kindes oder aktuelle Schul-
                  bzw. Inskriptionsbestätigung, Mitversicherungsbestätigung)
                </li>
              </ul>
            </div>
            <h3 className="h3">
              <WarningIcon /> Wichtig
            </h3>
            <div className="copytext">
              <ul className="simple">
                <li>maximal 5 Dokumente (je 15 MB Dateigröße)</li>
                <li>mindestens 1 Dokument</li>
              </ul>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ControlledFileUpload name="documents" label="Unterlagen hochladen" onFileUpload={props.onFileUpload} control={props.control} />
        </Grid>
      </Grid>
    </>
  );
}
