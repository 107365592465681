import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { formElementSize } from "../../utils/constants";

type Props = {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  control: Control<any>;
  options: { value: string; label: string }[];
};

export default function ControlledSelect(props: Props) {
  return (
    <FormControl fullWidth size={formElementSize}>
      <InputLabel>{props.label}</InputLabel>
      <Controller
        name={props.name}
        control={props.control}
        rules={{ required: props.required }}
        render={({
          field: { onChange, value, disabled },
          fieldState: { error },
        }) => (
          <Select
            error={!!error}
            required={props.required}
            disabled={props.disabled ?? disabled}
            onChange={onChange}
            value={value}
            fullWidth
            label={props.label}
            variant="outlined"
          >
            {props.options?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
}
