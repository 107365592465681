import Grid from "@mui/material/Grid";
import { Control } from "react-hook-form";
import ControlledNumberInput from "../FormElements/ControlledNumberInput";
import ControlledSwitch from "../FormElements/ControlledSwitch";
import { Collapse } from "@mui/material";

type Props = {
  control: Control<any>;
  watch?: (
    names?: string | string[] | ((data: any, options: any) => void)
  ) => unknown;
};

export default function FormPartReimbursement(props: Props) {
  const watchReimbursementOGK = props.watch?.("reimbursementOGK") as boolean;
  const watchReimbursementPrivate = props.watch?.(
    "reimbursementPrivate"
  ) as boolean;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3 className="h3">Angaben zum Kostenersatz</h3>
        </Grid>
        <Grid item xs={12}>
          <ControlledSwitch
            name="reimbursementOGK"
            label="Wurde die Rechnung bei der ÖGK eingereicht und wurde ein Kostenersatz erstattet?"
            control={props.control}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{ marginTop: watchReimbursementOGK ? "0" : "-16px" }}
        >
          <Collapse in={watchReimbursementOGK}>
            <ControlledNumberInput
              name="reimbursementOGKAmount"
              label="Erstatteter Betrag"
              type="money"
              required={watchReimbursementOGK}
              control={props.control}
            />
          </Collapse>
        </Grid>

        <Grid item xs={12}>
          <ControlledSwitch
            name="reimbursementPrivate"
            label="Wurde die Rechnung bei einer provaten Versicherung eingereicht und wurde ein Kostenersatz erstattet?"
            control={props.control}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{ marginTop: watchReimbursementPrivate ? "0" : "-16px" }}
        >
          <Collapse in={watchReimbursementPrivate}>
            <ControlledNumberInput
              name="reimbursementPrivateAmount"
              label="Erstatteter Betrag"
              type="money"
              required={watchReimbursementPrivate}
              control={props.control}
            />
          </Collapse>
        </Grid>
      </Grid>
    </>
  );
}
