import { create } from "zustand";
import axios, { AxiosResponse } from "axios";
import {Dayjs} from "dayjs";

export interface FormType {
  token: string | null;
  captchaResponse: string | null;
  firstName: string | null;
  lastName: string | null;
  address: string | null;
  zip: string | null;
  city: string | null;
  socialSecurityNumber: string | null;
  birthday: string | Dayjs | null;
  email: string | null;
  phone: string | null;
  familyMember: boolean;

  firstName_fm: string | null;
  lastName_fm: string | null;
  address_fm: string | null;
  zip_fm: string | null;
  city_fm: string | null;
  socialSecurityNumber_fm: string | null;
  birthday_fm: string | Dayjs | null;
  email_fm: string | null;
  phone_fm: string | null;

  costOriginator: string | null;
  costDate: string | Dayjs | null;
  costAmount: string | null;
  reimbursementOGK: boolean;
  reimbursementOGKAmount: string | null;
  reimbursementPrivate: boolean;
  reimbursementPrivateAmount: string | null;
  serviceDescription: string | null;
  serviceDescriptionOther: string | null;
  iban: string | null;
  bic: string | null;
  documents: { name: string; type: string; file: File }[];
  tos: boolean;
}

export interface PicklistType {
  id: string;
  title: string;
  elements: PicklistElementType[];
}

export interface PicklistElementType {
  id: string;
  content: string;
}

interface FormService {
  postForm: (dto: FormType) => Promise<AxiosResponse<any>>;
  getPicklists: () => Promise<AxiosResponse<PicklistType[]>>;
  downloadPdf: (dto: FormType) => Promise<AxiosResponse<any>>;
}

const apiPath = "/api/Form";

const useFormService = create<FormService>((set, get) => ({
  postForm: async (dto) => {
    const resp = await axios.post(`${apiPath}`, dto);
    return resp;
  },
  getPicklists: async () => {
    const resp = await axios.get(`${apiPath}/picklists`);
    return resp;
  },
  downloadPdf: async (dto: FormType) => {
    const resp = await axios.post(`${apiPath}/downloadPdf`, dto, { responseType: "blob" });
    return resp;
  }
}));

export default useFormService;
