import Grid from "@mui/material/Grid";
import { Control } from "react-hook-form";
import ControlledTextField from "../FormElements/ControlledTextField";
import ControlledDatePicker from "../FormElements/ControlledDatePicker";
import ControlledNumberInput from "../FormElements/ControlledNumberInput";

type Props = {
  control: Control<any>;
};

export default function FormPartCostDetails(props: Props) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3 className="h3">
            Rechnungs&shy;angaben
          </h3>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name="costOriginator"
            label="Rechnungsaussteller:in"
            rules={{ required: "Bitte Rechnungsausteller:in angeben" }}
            control={props.control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledDatePicker
            name="costDate"
            label="Rechnungsdatum"
            rules={{ required: "Bitte Rechnungsdatum angeben" }}
            control={props.control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledNumberInput
            name="costAmount"
            label="Rechnungs&shy;betrag"
            rules={{ required: "Bitte Rechnungs&shy;betrag angeben" }}
            type="money"
            control={props.control}
          />
        </Grid>
      </Grid>
    </>
  );
}
