import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

type Props = {
  open: boolean;
  onClose: () => void;
};

export default function DialogTos(props: Props) {

  return (
    <Dialog onClose={props.onClose} open={props.open} maxWidth="md">
      <DialogTitle>Wichtige Hinweise </DialogTitle>
      <DialogContent dividers>
        <div className="copytext">
        <h3 className="h3">Gewährung des Zuschusses</h3>
          <p>
            Ich versichere, dass ich sämtliche Angaben in diesem Antrag nach
            bestem Wissen und Gewissen richtig und vollständig gemacht habe.
            Sollte ich nachträglich erkennen, dass der vorstehende Antrag
            unrichtig und unvollständig ist, so werde ich die Privatstiftung
            unverzüglich in Kenntnis setzen. Mir ist bekannt, dass aufgrund
            unrichtiger oder unvollständiger Angaben gewährte Unterstützungen
            von der Stiftung zurückgefordert werden können.
          </p>
          <p>
            Des Weiteren ist mir bewusst, dass das Einbringen eines
            betrügerischen Antrages rechtliche Konsequenzen nach sich ziehen
            wird. Dies gilt beispielsweise für den Fall, dass für die beantragte
            Leistung ein Kostenersatz der ÖGK oder aus der
            Krankenversicherungspolizze eines anderen Versicherers nicht oder
            falsch angegeben wurde. Einwilligungserklärung über die Verarbeitung
            personenbezogener Daten der Begünstigten der Privatstiftung gem. §
            718 Abs. 9 ASVG zur betrieblichen Gesundheitsförderung - Donawitz
            Ich willige als Begünstigte:r bzw. als Angehörige:r einer/eines
            Begünstigten der Privatstiftung gem. § 718 Abs. 9 ASVG zur
            betrieblichen Gesundheitsförderung - Donawitz freiwillig und
            ausdrücklich in die Verarbeitung der umseitig angeführten
            personenbezogenen Daten ein. Sofern ich den Antrag mit Einwilligung
            bzw. als berechtigter Vertreter für eine/n Angehörige/n stelle,
            werden dieselben personenbezogenen Daten für die/den Angehörige/n
            verarbeitet.
          </p>
          <p>
            Die Verarbeitung erfolgt ausschließlich zum Zwecke der Prüfung der
            etwaigen Gewährung eines Zuschusses aus der Stiftung. Bei den
            personenbezogenen Daten zur Prüfung der etwaigen Gewährung eines
            Zuschusses aus der Stiftung handelt es sich konkret um meinen Namen
            als Antragsteller:in, meine Sozialversicherungsnummer, mein
            Geburtsdatum, meine E-Mail-Adresse, meine Anschrift, meine
            Telefonnummer, die Angabe des Rechnungsausstellers, den
            Rechnungsbetrag, das Rechnungsdatum, die Angaben zum Kostenersatz
            (ob die Rechnung bereits bei einer gesetzlichen Krankenversicherung
            und/oder bei einer privaten Versicherung eingereicht wurde und ob
            dahingehend ein Kostenersatz erstattet wurde), die
            Leistungsbeschreibung (zB. Mundhygiene, etc.) und meine Bankdaten
            (IBAN, BIC,). Die Verarbeitung (insbesondere Speicherung, etc.)
            meiner personenbezogenen Daten erfolgt auf Grund meiner erteilten
            Einwilligung (Art 6 Abs. 1 lit. a DSGVO sowie Art. 9 Abs. 2 lit. a
            DSGVO).
          </p>
          <p>
            Diese Einwilligung gilt unbeschadet meines Widerrufsrechts zeitlich
            unbefristet.
          </p>
          <p>
            Ich nehme mein Recht zur Kenntnis, diese Einwilligung an die
            Privatstiftung gem. § 718 Abs. 9 ASVG zur betrieblichen
            Gesundheitsförderung - Donawitz, als „Verantwortlicher“ im Sinne des
            Art. 4 Z 7 DSGVO, jederzeit mittels Briefes an die Privatstiftung
            gem. § 718 Abs. 9 ASVG zur betrieblichen Gesundheitsförderung -
            Donawitz, Kerpelystr. 201, A-8700 Leoben, oder E-Mails an
            office@privatstiftung-donawitz.at zu widerrufen, ohne dass die
            Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
            erfolgten Verarbeitung berührt wird. Außerdem habe ich das Recht auf
            Auskunft, Berichtigung, Löschung, Einschränkung, Widerspruch sowie
            auf Datenübertragbarkeit. Ich habe außerdem die Möglichkeit,
            Beschwerde bei der zuständigen Aufsichtsbehörde zu erheben, wenn ich
            der Meinung bin, dass meine Daten unrechtmäßig verarbeitet werden.
          </p>
          <p>
            Sie erreichen den Datenschutzbeauftragten unter
            datenschutz-donawitz@voestalpine.com, unter der postalischen
            Anschrift der Privatstiftung sowie unter +43 / 664 / 88324532.
          </p>
        <h3 className="h3">Information zur möglichen Steuerpflicht</h3>
          <p>
            Wir weisen Sie darauf hin, dass die beantragten Zuschüsse für Sie
            steuerpflichtig sein könnten! Beziehen Sie Leistungen von der
            Privatstiftung Donawitz, die insgesamt € 730,- pro Kalenderjahr
            (Freibetragsgrenze Stand 2022) übersteigen oder beziehen Sie neben
            Ihren lohnsteuerpflichtigen Einkünften (wie bspw.
            Lohn/Gehalt/Pension) noch andere steuerpflichtige Einkünfte und
            übersteigt der von Ihnen dadurch bezogene Gesamtbetrag (Leistungen
            aus der Privatstiftung Donawitz und andere steuerpflichtige
            Einkünfte) € 730,- pro Kalenderjahr, könnten die erhaltenen
            Einkünfte der Steuerpflicht unterliegen. In diesem Fall sind Sie
            dazu verpflichtet, für das betreffende Kalenderjahr eine
            Arbeitnehmerveranlagung oder eine Einkommensteuererklärung bei Ihrem
            zuständigen Finanzamt einzureichen, in der Sie Ihre Bezüge als
            steuerpflichtige Einkünfte deklarieren. Nähere Auskünfte dazu
            entnehmen Sie bitte auch dem Informationsblatt zur Steuerpflicht,
            das in der Privatstiftung Donawitz aufliegt.
          </p>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} autoFocus>
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
