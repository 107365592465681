import { Box } from "@mui/material";
import "./Main.scss";
import { Outlet } from "react-router-dom";

export default function Main() {
  return (
    <Box component="main" className="main">
      <Box component="div" className="main__wrap">
        <div className="main__masthead">
          <h1 className="h1">
            Privatstiftung zur betrieblichen Gesundheitsförderung - Donawitz
          </h1>
          <h2 className="h2">Antrag auf Zuschuss aus der Privatstiftung</h2>
        </div>
        <Box component="section" className="section">
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}
