import { Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function RegistrationSuccess() {
  return (
    <>
      <Box component="div" className="section__main">
        <div className="notice notice--important">
          <h3 className="h3">
            <CheckCircleIcon /> Vielen Dank!
          </h3>
          <p className="copytext">
            Ihre Anmeldung war erfolgreich.<br></br>
            Eine automatische E-Mail mit dem Zugriffscode wurde an die Adresse
            versandt, die Sie bei der Anmeldung angegeben haben. Bitte überprüfen Sie Ihre E-Mails.
          </p>
        </div>
      </Box>
    </>
  );
}
