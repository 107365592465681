import { create } from "zustand";
import axios, { AxiosResponse } from "axios";

export interface TokenType {
  firstName: string;
  lastName: string;
  email: string;
  captchaResponse: string;
}

interface TokenService {
  sendToken: (token: TokenType) => Promise<AxiosResponse<any>>;
}

const apiPath = "/api/Token";

const useTokenService = create<TokenService>((set, get) => ({
  sendToken: async (token) => {
    const resp = await axios.post(`${apiPath}/send`, token);
    return resp;
  },
}));

export default useTokenService;
