import { TextField } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { formElementSize } from "../../utils/constants";

type Props = {
  name: string;
  label: string;
  rules?: any;
  inputProps?: any;
  disabled?: boolean;
  control: Control<any>;
  type?: string;
};

export default function ControlledTextField(props: Props) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{ ...props.rules }}
      render={({
        field: { onChange, value, disabled },
        fieldState: { error },
      }) => (
        <>
          <TextField
            helperText={error ? error.message : null}
            size={formElementSize}
            error={!!error}
            required={!!props.rules?.required}
            disabled={props.disabled ?? disabled}
            onChange={onChange}
            value={value}
            fullWidth
            label={props.label}
            variant="outlined"
            type={props.type ?? "text"}
            InputProps={{ ...props.inputProps }}
          />
        </>
      )}
    />
  );
}
