import { Box, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DownloadIcon from "@mui/icons-material/Download";
import {saveAs} from "file-saver";
import {useLocation} from "react-router-dom";
import useFormService from "../services/FormService.ts";

export default function Success() {
  const location = useLocation();
  const downloadFormular = useFormService((state) => state.downloadPdf);

  const downloadPdf = async () => {
    const serverResp = await downloadFormular(location.state.dto);

    if (serverResp.data instanceof Blob) {
      saveAs(serverResp.data, "Formular.pdf");
    }
  }

  return (
    <>
      <Box component="div" className="section__main">
        <div className="notice notice--important">
          <h3 className="h3">
            <CheckCircleIcon /> Vielen Dank!
          </h3>
          <p className="copytext">
            Ihr Antrag wurde erfolgreich erstellt und eingereicht.<br></br>
            Eine automatische E-Mail mit dem Antrag wurde an die Adresse
            versandt, die Sie im Antrag angegeben haben. Sie können die Datei
            auch hier noch einmal herunterladen.
          </p>
        </div>
        <h3 className="h3">Antrag als PDF herunterladen</h3>
        <Button variant="contained" startIcon={<DownloadIcon />} onClick={() => downloadPdf()}>
          PDF Herunterladen
        </Button>
      </Box>
    </>
  );
}

