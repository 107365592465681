import { FormControlLabel, FormGroup, Switch, TextField } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { formElementSize } from "../../utils/constants";

type Props = {
  name: string;
  label: string | JSX.Element;
  required?: boolean;
  disabled?: boolean;
  control: Control<any>;
};

export default function ControlledSwitch(props: Props) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{ required: props.required }}
      render={({
        field: { onChange, value, disabled },
        fieldState: { error },
      }) => {
        const controlledSwitch = (
          <Switch
            size={formElementSize}
            required={props.required}
            disabled={props.disabled ?? disabled}
            onChange={onChange}
            value={value}
          />
        );

        return (
          <FormControlLabel control={controlledSwitch} label={props.label} style={{alignItems: "flex-start", marginInline: "0"}} />
        );
      }}
    />
  );
}
