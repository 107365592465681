import { Control, Controller } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/de";
import { formElementSize } from "../../utils/constants";

type Props = {
  name: string;
  label: string;
  rules?: any;
  disabled?: boolean;
  control: Control<any>;
};

export default function ControlledDatePicker(props: Props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
      <Controller
        name={props.name}
        control={props.control}
        rules={{ ...props.rules }}
        render={({
          field: { onChange, value, disabled },
          fieldState: { error },
        }) => (
          <DatePicker
            value={value}
            onChange={onChange}
            label={props.label}
            disabled={props.disabled ?? disabled}
            slotProps={{ textField: { size: formElementSize, fullWidth: true, required: !!(props.rules ? props.rules.required : false) } }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
