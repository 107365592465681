import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import Registration from './pages/Registration.tsx';
import Login from './pages/Login.tsx';
import ApplicationForm from './pages/ApplicationForm.tsx';
import Success from './pages/Success.tsx';
import RegistrationSuccess from './pages/RegistrationSuccess.tsx';
//import './index.css'

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: "/",
        element: <Navigate to="/registration" replace={true} />,
      },
      {
        path: "registration",
        element: <Registration />,
      },
      {
        path: "registration-success",
        element: <RegistrationSuccess />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "application-form",
        element: <ApplicationForm />,
      },
      {
        path: "success",
        element: <Success />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
)
