import Grid from "@mui/material/Grid";
import { Box, Button, Collapse, Divider, Link } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import FormPartApplicant from "../components/ApplicationForm/FormPartApplicant";
import FormPartFamilyMember from "../components/ApplicationForm/FormPartFamilyMember";
import ControlledSwitch from "../components/FormElements/ControlledSwitch";
import FormPartCostDetails from "../components/ApplicationForm/FormPartCostDetails";
import FormPartReimbursement from "../components/ApplicationForm/FormPartReimbursement";
import FormPartServiceDescription from "../components/ApplicationForm/FormPartServiceDescription";
import { useEffect, useState } from "react";
import FormPartBankData from "../components/ApplicationForm/FormPartBankData";
import FormPartDocuments from "../components/ApplicationForm/FormPartDocuments";
import DialogTos from "../components/ApplicationForm/DialogTos";
import useFormService, {
  DtoFormType,
  FormType,
  PicklistElementType,
} from "../services/FormService";
import * as dayjs from "dayjs";
import {Dayjs} from "dayjs";

const defaultValues: FormType = {
  token: "",
  captchaResponse: "",
  firstName: "",
  lastName: "",
  address: "",
  zip: "",
  city: "",
  socialSecurityNumber: "",
  birthday: null,
  email: "",
  phone: "",

  familyMember: false,

  firstName_fm: "",
  lastName_fm: "",
  address_fm: "",
  zip_fm: "",
  city_fm: "",
  socialSecurityNumber_fm: "",
  birthday_fm: null,
  email_fm: "",
  phone_fm: "",

  costOriginator: "",
  costDate: null,
  costAmount: "",

  reimbursementOGK: false,
  reimbursementOGKAmount: "",
  reimbursementPrivate: false,
  reimbursementPrivateAmount: "",

  serviceDescription: "",
  serviceDescriptionOther: "",

  iban: "",
  bic: "",

  documents: [],

  tos: false,
};

export default function ApplicationForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { control, handleSubmit, watch } = useForm<FormType>({
    // prefill form with data from location.state passed from registration
    defaultValues: {
      ...defaultValues,
      ...location.state,
      token: location.state?.accessCode,
    },
  });
  const watchFamilyMember = watch("familyMember", false);
  const watchTos = watch("tos", false);
  const [isTosOpen, setIsTosOpen] = useState(false);
  const [documents, setDocuments] = useState<any[]>([]);

  const [serviceDescriptionPicklist, setServiceDescriptionPicklist] = useState<
    { value: string; label: string }[]
  >([]);
  const getPicklists = useFormService((state) => state.getPicklists);
  const postForm = useFormService((state) => state.postForm);

  const onTosCloseHandler = () => {
    setIsTosOpen(false);
  };
  
  const onSubmitHandler: SubmitHandler<FormType> = async (data: FormType) => {
    let dto: FormType = { ...data };
    
    dto.birthday = data.birthday?.toISOString();
    dto.costDate = data.costDate?.toISOString();
    dto.birthday_fm = data.birthday_fm?.toISOString() ?? null;
  
    dto.documents = documents;

    let resp = await postForm(dto).catch((err) => {
      console.log("error from server", err);
    });
    if (resp) {
      navigate("/success", { state: { dto: dto } });
    }
  };

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const onFileUploadHandler = (files: File[]) => {
    const arr: any[] = documents;
    files.forEach(async (file) => {
      const base64 = await toBase64(file);
      arr.push({
        fileName: file.name,
        mimeType: file.type,
        fileContent: base64,
      });
    });
    setDocuments(arr);
  };

  const fetchData = async () => {
    getPicklists().then((resp) => {
      // collect picklists for service description select
      const options =
        resp.data[0]?.elements.map((e: PicklistElementType) => ({
          value: e.id,
          label: e.content,
        })) ?? [];

      setServiceDescriptionPicklist(options);
    });
  };

  //if token gets undefined, redirect user to login page! 
  useEffect(() => {
    if (location.state?.accessCode === undefined) return navigate("/login");
  }, [location.state?.accessCode]);

  useEffect(() => {
    // fetch data from server
    fetchData();
  }, []);

  return (
    <>
      <Box component="div" className="section__main">
        <form
          className="form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <FormPartApplicant control={control} />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ControlledSwitch
                name="familyMember"
                label="Zuschuss wird für mitvers. Angehörige beantragt"
                control={control}
              />
            </Grid>
          </Grid>

          <Collapse
            in={watchFamilyMember}
            style={{ marginTop: watchFamilyMember ? "0" : "-20px" }}
          >
            <FormPartFamilyMember
              control={control}
              disabled={!watchFamilyMember}
            />
          </Collapse>

          <FormPartCostDetails control={control} />

          <FormPartReimbursement control={control} watch={watch} />

          <FormPartServiceDescription
            control={control}
            options={serviceDescriptionPicklist}
            watch={watch}
          />

          <FormPartBankData control={control} />

          <FormPartDocuments
            control={control}
            onFileUpload={onFileUploadHandler}
          />

          <Divider />

          <Grid container spacing={4}>
            <Grid item xs={12}>
              <ControlledSwitch
                name="tos"
                label={
                  <>
                    Hiermit erkläre ich, die{" "}
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        setIsTosOpen(true);
                      }}
                    >
                      Information zur möglichen Steuerpflicht
                    </Link>{" "}
                    sowie die{" "}
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        setIsTosOpen(true);
                      }}
                    >
                      Voraussetzungen und Hinweise
                    </Link>{" "}
                    für die Gewährung des Zuschusses gelesen und verstanden zu
                    haben. Weiters nehme ich mit meiner Unterschrift die
                    umseitige Einwilligungserklärung über die Verarbeitung
                    personenbezogener Daten zur Kenntnis und stimme dieser
                    ausdrücklich zu.
                  </>
                }
                required={true}
                control={control}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                startIcon={<ArrowForwardIcon />}
                type="submit"
                disabled={!watchTos}
              >
                Absenden
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box component="aside" className="section__aside">
        <div className="notice notice--important">
          <h3 className="h3">
            <WarningIcon /> WICHTIG!
          </h3>
          <p className="copytext">
            Wir weisen Sie darauf hin, dass die beantragten Zuschüsse für Sie
            steuerpflichtig sein könnten! Beziehen Sie Leistungen von der
            Privatstiftung Donawitz, die insgesamt € 730,- pro Kalenderjahr
            (Freibetragsgrenze Stand 2022) übersteigen oder beziehen Sie neben
            Ihren lohnsteuerpflichtigen Einkünften (wie bspw.
            Lohn/Gehalt/Pension) noch andere steuerpflichtige Einkünfte und
            übersteigt der von Ihnen dadurch bezogene Gesamtbetrag (Leistungen
            aus der Privatstiftung Donawitz und andere steuerpflichtige
            Einkünfte) € 730,- pro Kalenderjahr, könnten die erhaltenen
            Einkünfte der Steuerpflicht unterliegen. In diesem Fall sind Sie
            dazu verpflichtet, für das betreffende Kalenderjahr eine
            Arbeitnehmerveranlagung oder eine Einkommensteuererklärung bei Ihrem
            zuständigen Finanzamt einzureichen, in der Sie Ihre Bezüge als
            steuerpflichtige Einkünfte deklarieren. Nähere Auskünfte dazu
            entnehmen Sie bitte auch dem Informationsblatt zur Steuerpflicht,
            das in der Privatstiftung Donawitz aufliegt.
          </p>
        </div>
      </Box>
      <DialogTos open={isTosOpen} onClose={onTosCloseHandler} />
    </>
  );
}
