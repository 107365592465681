import HCaptcha from "@hcaptcha/react-hcaptcha";

type Props = {
  onCaptchaCallback: (token: string, ekey: string) => void;
};

export default function Captcha(props: Props) {
  const hCaptchaSiteKey = "7229f2f2-f9f2-4476-ace0-33acd05641d0";
  const hCaptchaSecretKey = "ES_7961e4855f744a1999e21ecb441c6660";
  const hCaptchaResponseToken = "10000000-aaaa-bbbb-cccc-000000000001";

  return (
    <>
      <HCaptcha
        sitekey={hCaptchaSiteKey}
        onVerify={props.onCaptchaCallback}
      />
    </>
  );
}
