import { Box } from "@mui/material";
import LogoMain from "./LogoMain";
import "./Header.scss";

export default function Header() {
  return (
    <Box component="header" className="header">
      <Box component="div" className="header__wrap">
        <LogoMain />
      </Box>
    </Box>
  );
}
