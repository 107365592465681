import { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Grid from "@mui/material/Grid";
import { SubmitHandler, useForm } from "react-hook-form";
import ControlledTextField from "../components/FormElements/ControlledTextField";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

interface FormData {
  accessCode: string;
}

const defaultValues = {
  accessCode: "",
};

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [prefilledData, setPrefilledData] = useState<{firstName: string, lastName: string, email: string, accessCode: string} | null>(null);
  const { control, handleSubmit, setValue } = useForm<FormData>({
    defaultValues,
  });

  const onSubmitHandler: SubmitHandler<FormData> = (data) => {
    // transfer preffiled data to application form
    navigate("/application-form", { state: { ...location.state, ...prefilledData, accessCode: data.accessCode } });
  };

  useEffect(() => {
    const accessCode = searchParams.get("accessCode") ?? "";
    const firstName = searchParams.get("firstName") ?? "";
    const lastName = searchParams.get("lastName") ?? "";
    const email = searchParams.get("email") ?? "";
    setPrefilledData({firstName, lastName, email, accessCode});
    if (!!accessCode) {
      setValue("accessCode", accessCode);
    }
  }, [searchParams]);

  return (
    <>
      <Box component="div" className="section__main">
        <form autoComplete="off" onSubmit={handleSubmit(onSubmitHandler)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3 className="h3">Einloggen mit Ihrem Zugriffscode</h3>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ControlledTextField
                name="accessCode"
                label="Zugriffscode"
                rules={{ required: "Bitte Zugriffscode angeben" }}
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                startIcon={<ArrowForwardIcon />}
                type="submit"
              >
                Einloggen
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box component="aside" className="section__aside">
        <div className="notice notice--important">
          <h3 className="h3">
            <InfoIcon /> Ihr Zugriffscode
          </h3>
          <p className="copytext">
            Sie bekommen nach dem Absenden Ihrer Daten einen Zugriffscode
            zugesendet. Nach erfolgreicher Authentifizierung erhalten Sie
            Zugriff auf das Antragsformular.
          </p>
        </div>
      </Box>
    </>
  );
}
