import { Box } from "@mui/material";
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import "./Footer.scss";

export default function Footer() {
  return (
    <Box component="footer" className="footer">
      <Box component="div" className="footer__wrap">
        <div className="footer__item">© 2024 Privatstiftung zur betrieblichen Gesundheitsförderung - Donawitz</div>
        <div className="footer__item"><FmdGoodIcon /> Kerpelystraße 201, Leoben</div>
        <div className="footer__item"><a href="tel:050304252120">T. 050304 - 25 2120</a></div>
        <div className="footer__item"><a href="mailto:office@privatstiftung-donawitz.at">office@privatstiftung-donawitz.at</a></div>
        <div className="footer__item"><a href="https://www.privatstiftung-donawitz.at/privatstiftung-donawitz/de/impressum/" target="_blank">Impressum</a></div>
      </Box>
    </Box>
  );
}
